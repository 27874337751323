.columnLink {
	padding: 0px !important;
	border: 0;
	height: auto;
}

.leftButton {
	float: left;
}

.skeleton {

	:global {
		.ant-skeleton-title {
			margin: 0;
			height: 14px;
			margin: 4px 0 4px;
		}
	}
}

.actions {
	margin: -3px 0;
	height: 21px;
}

.descriptions {
	:global {
		.ant-descriptions-item-label {
			font-weight: bold;
			margin-bottom: -8px !important;
		}
	}

	margin-bottom: 16px;
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;