.card {
	height: 100%;

	:global {
		.ant-divider {
			margin: 0 0 0 16px !important;
		}
	}
}

.cardInner {
	margin-top: 16px;
}

.tabs {
	margin-top: 8px;

	:global {
		.ant-table-container {
			border-bottom: 1px solid rgba(0, 0, 0, 0.06);

			table tr:last-child td {
				border-bottom: none;
			}
		}
	}
}

.columnLink {
	padding: 0px !important;
	border: 0;
	height: auto;
}

.drawer {
	:global {
		.ant-drawer-body {
			padding: 0 !important;

			>* {
				margin: 24px;
			}
		}
	}
}

@media (max-width: 767px) {
	.drawer {
		:global {
			.ant-drawer-body {

				>* {
					margin: 16px;
				}
			}
		}
	}
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;