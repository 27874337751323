.wrapper {
	padding: 24px;
}

:global(.screen-xs) .wrapper,
:global(.screen-sm) .wrapper {
	padding: 8px;
}

.chart {
	margin-top: 8px;
}

.card {
	height: 100%;

	:global {
		.ant-divider {
			margin: 0 0 0 16px !important;
		}

		.ant-card-head {
			img {
				width: 24px;
				margin-right: 8px;
			}

		}
	}
}

.columnLink {
	padding: 0px !important;
	border: 0;
	height: auto;
}

.tableCard {
	:global {
		.ant-card-body {
			padding: 0 !important;

			.ant-table-container {
				border-top: 1px solid @border-color-split;
			}
		}
	}
}

:global(.screen-xs) {
	.receiptsCard {
		:global {
			.ant-card-extra {
				display: none;
			}
		}

	}

	.actionButton {
		margin: -1px;
		width: calc(100% + 2px) !important;
		border-radius: 0;
		display: block;
	}
}

button.actionButton {
	display: none;
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;