.wrapper {
	padding: 16px 16px;
	border-bottom: 1px solid #f0f0f0;
	background-color: #fff;
}

.form {
	max-width: 800px;
}

.uploadIcon {
	margin-bottom: 10px !important;
	:global {
		img {
			height: 48px;
		}
	}
}

.upload {
	padding: 8px 8px !important;
	height: 120px !important;
	:global {
		.ant-upload-btn {
			padding: 0 !important;
		}
	}
}

.logoPreview {
	max-height: 100px;
	max-width: 170px;
}

.removeLogo {
	position: absolute;
	top: 8px;
	right: 8px;
}

@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;