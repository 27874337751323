.container {
	border: 1px solid #e9e9e9 !important;
	// padding: 8px 0px 0px 8px;

	:global {
		.ant-upload-list {
			margin: 8px 8px 0 8px !important;
		}
	}
}

.uploadButton {
	border: none !important;
	background: none !important;
}

.uploadButtonInner {
	:global {
		img {
			width: 32px;
		}

		div {
			margin-top: 8px;
		}
	}
}

.empty {
	:global {
		.ant-upload.ant-upload-select-picture-card {
			width: 100%;
			height: auto;
			border: none;
		}

		.ant-upload {
			margin-bottom: 0 !important;
		}

		.ant-upload-list {
			margin: 0px !important;
		}
	}
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;