.page {
	page-break-after: always;
	width: 100%;

	:global {
		* {
			font-size: 1em !important;
			line-height: 1em !important;
			letter-spacing: normal !important;
			color: #000 !important;
		}

		h2 {
			font-weight: bold;
			font-size: 1.5em !important;
			margin: 0;
		}

		h3 {
			font-weight: bold;
			font-size: 1em !important;
			margin: 0;
		}

		small {
			font-size: 0.8em !important;
			line-height: 0.8em !important;
		}

		hr {
			border: 0;
			border-top: 1px solid #000
		}

		p {
			margin: 0;
		}
	}
}

@media print {
	.page {
		page-break-after: always;
	}
}

.alignRight {
	text-align: right !important;
}

.alignLeft {
	text-align: left !important;
}

.title {
	width: 100%;
	text-align: center;
	position: relative;
	margin: 2px 0 !important;

	:global {
		span {
			padding: 0 16px;
			background: white;
			position: relative;
		}
	}
}

.title:before {
	content: '';
	font-size: inherit;
	display: block;
	position: absolute;
	right: 0;
	left: 0;
	top: 35%;
	bottom: 35%;
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
}

.subTitle {
	position: relative;
	margin: 2px 0 !important;

	:global {
		span {
			padding: 0 16px;
			background: white;
			position: relative;
		}
	}
}

.subTitle:before {
	content: '';
	font-size: inherit;
	display: block;
	position: absolute;
	right: 0;
	left: 0;
	top: 50%;
	border-top: 1px solid #000;
}

// .notFiscalReceipt {
// 	// font-size: 28px;
// }

.qr {
	width: 384px;
	max-width: 100%;
	aspect-ratio: 1/1;
}

.tdRight {
	text-align: right;
}

.tdLeft {
	text-align: left;
}

.tdCenter {
	text-align: center;
}

.items {
	width: 100%;
	margin-left: auto;
}

.payment {
	width: 100%;
	margin-left: auto;
}

.taxItems {
	width: 100%;
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;