.se-container * {
	font-family: Montserrat, sans-serif !important;
}

.se-dialog-content {
	box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.08) 0px 6px 16px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px !important;
	-webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.08) 0px 6px 16px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px !important;

	* {
		font-family: Montserrat, sans-serif !important;
		font-weight: normal !important;
	}
}

.se-dialog-header .se-modal-title {
	font-size: 16px !important;
	font-weight: 800 !important;
}

.se-btn-tray * {
	font-size: 14px !important;
}

.se-btn.se-dialog-close {
	border: none !important;

	svg path {
		fill: lighten(@text-color, 30%) !important;
	}
}

.se-btn.se-dialog-close:hover {
	border: none !important;

	svg path {
		fill: @text-color !important;
	}
}

.se-btn {
	line-height: 1.5715;
	box-shadow: rgba(0, 0, 0, 0.016) 0px 2px !important;
	cursor: pointer;
	height: 32px !important;
	font-size: 14px !important;
	color: rgba(0, 0, 0, 0.85) !important;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
	padding: 4px !important;
	border-radius: 2px !important;
	border: 1px solid rgb(217, 217, 217) !important;
	background: rgb(255, 255, 255) !important;
}

.se-btn:hover {
	border-color: @primary-color !important;
}

.se-btn:hover svg path {
	fill: @primary-color !important;
}

.se-btn:disabled svg path {
	fill: @border-color-base !important;
}

.se-btn-module-border {
	border: 0 !important;
}

.se-toolbar {
	background-color: @background-color-light !important;
	outline: 1px solid @border-color-base !important;
}

.se-btn-primary {
	color: #fff !important;
	border-color: @primary-color !important;
	background: @primary-color !important;
	text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
	box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.se-btn-primary:active {
	box-shadow: 0 2px 0 rgb(0 0 0 / 5%) !important;
}

.se-input-form {
	box-sizing: border-box;
	margin: 0 !important;
	padding: 0 !important;
	font-variant: tabular-nums !important;
	list-style: none !important;
	font-feature-settings: 'tnum', "tnum" !important;
	padding: 4px 11px !important;
	color: rgba(0, 0, 0, 0.85) !important;
	font-size: 14px !important;
	line-height: 1.5715 !important;
	background-color: #fff !important;
	background-image: none;
	border: 1px solid @border-color-base !important;
	border-radius: 2px !important;
	transition: all 0.3s;

	&:focus {
		border-color: #a7afd4 !important;
		box-shadow: 0 0 0 2px rgb(126 137 200 / 20%) !important;
		border-right-width: 1px;
		outline: 0;
	}
}

// .jodit-container {
// 	background-color: #fff;
// }

// .jodit-toolbar__box:not(:empty) {
// 	background-color: @background-color-light !important;
// 	border-bottom: 1px solid @border-color-split !important;
// }

// .jodit-icon {
// 	stroke: @text-color !important;
// }

// .jodit-ui-separator {
// 	border-right: 1px solid @border-color-split !important;
// }

// .jodit-container {
// 	border: 1px solid @border-color-base !important;
// 	border-radius: 2px !important;


// }

// .jodit-popup {
// 	box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.08) 0px 6px 16px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px !important;
// 	-webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.08) 0px 6px 16px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px !important;

// 	* {
// 		font-family: Montserrat, sans-serif !important;
// 	}
// }

// .jodit-ui-button {
// 	line-height: 1.5715;
// 	box-shadow: rgba(0, 0, 0, 0.016) 0px 2px !important;
// 	cursor: pointer;
// 	height: 32px !important;
// 	font-size: 14px !important;
// 	color: rgba(0, 0, 0, 0.85) !important;
// 	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
// 	padding: 4px 15px !important;
// 	border-radius: 2px !important;
// 	border: 1px solid rgb(217, 217, 217) !important;
// 	background: rgb(255, 255, 255) !important;
// }

// .jodit-ui-button.jodit-ui-button_variant_primary,
// .jodit-ui-button[aria-pressed=true]:not([disabled]) {
// 	color: #fff !important;
// 	border-color: @primary-color !important;
// 	background: @primary-color !important;
// 	text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
// 	box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
// }


// .jodit-tabs__buttons {
// 	margin-bottom: 24px !important;
// }

// .jodit-ui-input {
// 	margin-bottom: 24px !important;

// 	.jodit-ui-input__label {
// 		padding: 0 0 8px !important;
// 		line-height: 1.5715 !important;
// 		white-space: initial;
// 		color: @text-color !important;
// 		font-size: 14px !important;
// 	}

// 	.jodit-ui-input__wrapper {
// 		border: none !important;

// 		.jodit-ui-input__input {
// 			box-sizing: border-box;
// 			margin: 0 !important;
// 			padding: 0 !important;
// 			font-variant: tabular-nums !important;
// 			list-style: none !important;
// 			font-feature-settings: 'tnum', "tnum" !important;
// 			padding: 4px 11px !important;
// 			color: rgba(0, 0, 0, 0.85) !important;
// 			font-size: 14px !important;
// 			line-height: 1.5715 !important;
// 			background-color: #fff !important;
// 			background-image: none;
// 			border: 1px solid @border-color-base !important;
// 			border-radius: 2px !important;
// 			transition: all 0.3s;

// 			&:focus {
// 				border-color: #a7afd4 !important;
// 				;
// 				box-shadow: 0 0 0 2px rgb(126 137 200 / 20%) !important;
// 				border-right-width: 1px;
// 				outline: 0;
// 			}
// 		}
// 	}
// }

// .jodit-form__table-creator-box {
// 	.jodit_hovered {
// 		background-color: @primary-color !important;
// 		border-color: @primary-color !important;
// 	}
// }

// .jodit-status-bar {
// 	background-color: @background-color-light !important;
// }

// .jodit-workplace+.jodit-status-bar:not(:empty) {
// 	border-top: 1px solid @border-color-split !important;
// }

// .jodit-add-new-line span {
// 	border-color: @border-color-base !important;
// 	background-color: @background-color-light !important;
// 	left: calc(100% - 29px) !important;
// }

// .jodit-add-new-line:after {
// 	background-color: @border-color-base !important;
// }

// .jodit-status-bar-link {
// 	display: none;
// }

// .jodit-toolbar-button {
// 	overflow: visible !important;

// 	button {
// 		height: 32px !important;
// 	}
// }



// .jodit-toolbar-button__button:hover:not([disabled]),
// .jodit-toolbar-button__trigger:hover:not([disabled]) {
// 	background-color: @background-color-base !important;
// 	border: 1px solid @border-color-base !important;
// }

// .jodit-toolbar-button__button {
// 	border: 1px solid transparent !important;
// }

// .jodit-toolbar-button_with-trigger_true {

// 	.jodit-toolbar-button__button:hover:not([disabled]),
// 	.jodit-toolbar-button__trigger:hover:not([disabled]) {
// 		border: 1px solid transparent !important;
// 	}
// }
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;