.card {
	height: 100%;
}

.tabs {
	margin-top: 8px;

	:global {
		.ant-table-container {
			border-bottom: 1px solid rgba(0, 0, 0, 0.06);

			table tr:last-child td {
				border-bottom: none;
			}
		}
	}
}

.columnLink {
	padding: 0px !important;
	border: 0;
	height: auto;
}

.drawer {
	:global {
		.ant-drawer-body {
			padding-bottom: 0;
		}
	}
}

.leftButtons {
	float: left;
}

.rightButtons {
	float: right;
}

.datePicker {
	:global {
		@media screen and (max-width: 600px) {
			.ant-picker-panel:last-child {
				position: absolute;

				.ant-picker-header {
					position: absolute;
					right: 0;
				}

				.ant-picker-header-view {
					display: none;
				}

				.ant-picker-body {
					display: none;
				}
			}
		}
	}
}

.printArea {
	display: none;
	background-color: white;

	:global {
		* {
			color: black;
		}

		div {
			.ant-table tr>*:first-child {
				padding: 4px 3px !important;
			}

			.ant-table tr>*:last-child {
				padding: 4px 3px !important;
			}

			table th {
				white-space: nowrap;
				border-right: 1px solid #000 !important;
				border-bottom: 1px solid #000 !important;
				background: #aaa !important;
			}

			table td {
				white-space: normal;
				border-right: 1px solid #000 !important;
				border-bottom: 1px solid #000 !important;
			}

			table th,
			table td {
				padding: 4px 3px !important;
			}

			table {
				border-top: 1px solid #000 !important;
				border-left: 1px solid #000 !important;
				margin-top: 15px;
			}

			h2 {
				padding: 15px 0 0 0;
			}

			h3 {
				padding: 15px 0 0 0;
			}

			h4 {
				padding: 15px 0 0 0;
			}

			table col {
				width: auto !important;
				min-width: auto !important;
			}
		}
	}
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;