.container {
	border: 1px solid #e9e9e9 !important;
	// padding: 8px 0px 0px 8px;

	&>span {
		display: flex;
		flex-direction: column-reverse;
		align-items: flex-start;
	}

	:global {
		.ant-upload-list-text {
			width: 100%;
		}

		.ant-upload.ant-upload-select-text {
			width: 100%;
			height: auto;
			border: none;
		}

		.ant-upload-drag-container {
			text-align: left;
		}

		.ant-upload-list {
			margin-top: 8px;

			// margin: 8px 8px 0 8px !important;
			.ant-upload-list-item {
				height: 32px;
				margin-top: 0;

				.ant-upload-list-item-info {
					padding: 0px 8px;
				}
			}
		}
	}
}

.uploadButton {
	border: none !important;
	background: none !important;

	:global {
		span {
			padding: 0 !important;
		}
	}
}

.uploadButtonInner {
	margin: 8px;

}

.empty {
	background-color: #fcfcfc;
	padding: 16px 0;

	:global {
		.ant-upload.ant-upload-select-text {
			width: 100%;
			height: auto;
			border: none;
		}

		.ant-upload {
			margin-bottom: 0 !important;
		}

		.ant-upload-list {
			margin: 0px !important;
		}
	}
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;