@import '~antd/es/style/themes/default.less';

.container {
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: auto;
	background-color: @layout-body-background;
}

.content {
	background: @primary-color;
	background: -moz-radial-gradient(center,
			ellipse cover,
			lighten(@primary-color, 30%) 0%,
			@primary-color 100%);
	background: -webkit-radial-gradient(center,
			ellipse cover,
			lighten(@primary-color, 30%) 0%,
			@primary-color 100%);
	background: radial-gradient(ellipse at center, lighten(@primary-color, 30%) 0%, @primary-color 100%);
	flex: 1;
	padding: 32px 0;

	:global {
		.ant-table {
			margin: 0 -24px;
			border-top: 1px solid @border-color-split;
		}

		.ant-table-small {

			td,
			th {
				padding: 8px 4px !important;
				font-size: 12px;
			}

			td:first-child {
				padding: 8px 0px !important;
			}
		}
	}
}

.top {
	text-align: center;
}

.smallSteps {
	:global {
		.ant-steps-item-content {
			width: 120px !important;
		}
	}
}

.available {
	color: #80c87e;
	font-size: 18px;
}

.unavailable {
	color: #c7c7c9;
	font-size: 18px;
}

.info {
	color: #7e89c8;
}

.form {
	width: 800px;
	margin: 40px auto;
	max-width: 100%;
	padding: 24px 24px 0;
	background: #fff;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

	.formInner {
		padding: 0 24px;
	}
}

.copyright {
	text-align: center;
	color: #fff;
	font-size: 12px;
	margin-top: 24px;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.panelTitle {
	text-align: center;
}

.successIcon {
	width: 128px;
	margin: 0 auto 24px;
}

.typeIcon {
	width: 128px;
	margin: 0 auto 24px;
}

.centered {
	text-align: center;
}

.typeCard {
	height: 100%;
	display: flex;
	flex-direction: column;

	:global {
		.ant-card-body {
			flex: 1;
		}
	}
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;