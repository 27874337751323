.hiddenPaymentMethod {
	display: none;
}

.printModes {
	float: left;
	padding-top: 4px;
}

.buttons {
	float: right;
}

.paymentMethodInput {
	width: 100%;

	:global {
		.ant-input-number-group-addon {
			padding: 0;

			.ant-btn-group {
				margin: -1px;
			}
		}
	}
}

.printArea {
	display: none;
	background-color: white;

	:global {
		* {
			color: black;
		}

		div {
			.ant-table tr>*:first-child {
				padding: 4px 3px !important;
			}

			.ant-table tr>*:last-child {
				padding: 4px 3px !important;
			}

			table th {
				white-space: nowrap;
				border-right: 1px solid #000 !important;
				border-bottom: 1px solid #000 !important;
				background: #aaa !important;
			}

			table td {
				white-space: normal;
				border-right: 1px solid #000 !important;
				border-bottom: 1px solid #000 !important;
			}

			table th,
			table td {
				padding: 4px 3px !important;
			}

			table {
				border-top: 1px solid #000 !important;
				border-left: 1px solid #000 !important;
				margin-top: 15px;
			}

			h2 {
				padding: 15px 0 0 0;
			}

			table col {
				width: auto !important;
				min-width: auto !important;
			}
		}
	}
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;