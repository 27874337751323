.erp-userMenu {
    float: right;
    margin: 0 0 0 16px;

    .clickArea {
        display: inline-block;
    }

    .ant-avatar {
        margin: 0 8px 0 0;
    }


}

.erp-userMenu-dropdown {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.avatarWrapper {

    position: relative;
    width: 32px;
    height: 32px;
    margin-top: 8px;

    span {
        position: absolute;
        width: 32px;
        height: 32px;
        line-height: 32px;
        left: 0;
        top: 0;
        text-align: center;
        color: #fff;
    }

    &.big {
        width: 48px;
        height: 48px;

        span {
            width: 48px;
            height: 48px;
            line-height: 48px;
            font-size: 24px;
        }
    }
}

.store-dropdown {
    padding: 0 16px;
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;