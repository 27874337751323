.page {
	page-break-after: always;
	width: 100%;
	:global {
		* {
			font-size: 9px !important;
		}
		h2 {
			font-weight: bold;
			font-size: 28px !important;
		}
		h3 {
			font-weight: bold;
			font-size: 16px !important;
		}
		h4 {
			font-weight: bold;
			font-size: 14px !important;
		}
	}
}

@media print {
	.page {
		page-break-after: always;
	}
}

.title {
	width: 100%;
	text-align: center;
}

.notFiscalReceipt {
	font-size: 28px;
}

.qr {
	width: 5cm;
	height: 5cm;
}

.tdRight {
	text-align: right;
}

.tdLeft {
	text-align: left;
}

.tdCenter {
	text-align: center;
}

.items {
	width: 100%;
	margin-left: auto;
}

.payment {
	margin-top: -1px;
	margin-left: auto;
}

.taxItems {
	margin-left: auto;
}

@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;