.container {
	flex: 1;
	display: flex;
	flex-direction: column;
	background-color: @component-background;
	height: 100%;
	width: 100%;
	padding-top: 8px;

	:global {
		.ant-spin-nested-loading {
			height: 100%;
		}
	}
}

.listDescription {
	color: @text-color !important;
}

.list {
	border-bottom: 1px solid @border-color-split;
}

.horizontalWrapper {
	position: relative;

	:global .Pane1 {

		.ant-alert {
			margin-top: -1px !important;
		}
	}
}

.selectedProduct {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 3px;
	background-color: @primary-color;
}

.scrollableList {
	overflow: auto;
	border-top: 1px solid @border-color-split;

	:global {
		.ant-list-item {
			position: relative;
		}

		.ant-list-item-action {
			margin-left: 0 !important;

			li {
				padding: 0 !important;
			}
		}
	}
}

.buttons {
	text-align: right;
	padding: 4px 0 0 0;
}

.rateWarning {
	margin-left: -1px !important;
	margin-bottom: -1px !important;
	border-radius: 0 !important;
	margin-right: -2px !important;
}

.selectStore {
	padding: 16px;
}

.image {
	position: absolute;
	height: 30px;
	top: 4px;
	margin-left: -2px;
}

.listImage {
	height: 32px;
	margin-right: -8px;
	margin-top: 8px;
	width: 32px;
}

.largeImage {
	height: 46px;
}

.selectStoreDropdown {
	width: 200px;
}

.posContent {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.saleContainer {
	padding: 24px 0 0 0;
}

.wrap {
	padding: 0 24px 4px;
}

:global(.screen-xs) .wrap {
	padding: 0 16px 4px;
}

.table {
	border-top: 1px solid #f0f0f0;
}

.tabs {
	margin: 0;
	border-bottom: none;

	:global .ant-tabs-tabpane {
		padding: 0 !important;
	}

	:global .ant-tabs-nav {
		position: absolute !important;
		top: -48px;
		z-index: 20;
		right: 62px;
		width: calc(100% - 216px);
		height: 46px;
		overflow: hidden;

		&::before {
			border-bottom: none !important;
		}
	}

	:global .ant-tabs-nav-list {
		height: 40px;
	}

	:global .ant-tabs-nav-wrap {
		margin: 0;
		// background: #fff;
		// box-shadow: inset -1px -1px 0px #eee;
		margin-right: -1px;
		height: 46px;

		&::before,
		&::after {
			height: 40px;
		}

		.ant-tabs-tab {
			padding: 8px 8px 8px 16px !important;
			z-index: 1;
			margin-right: -1px !important;
			border-radius: 0 !important;
			height: 40px;
			border-bottom: none !important;

			.ant-tabs-tab-remove {
				margin-left: 4px;
			}
		}

		.ant-tabs-tab-active::before {
			position: absolute;
			width: 100%;
			content: "";
			background-color: #fff;
			box-shadow: 0 0 8px #fff;
			bottom: -8px;
			height: 8px;
			left: 0;
		}
	}

	:global .ant-tabs-nav-add {
		border-bottom: none !important;
		border-radius: 0 !important;
		margin-left: 0px !important;
		z-index: 20;
		height: 40px;
	}

	:global .ant-tabs-nav-more {
		border: 1px solid @border-color-split !important;
		background-color: @background-color-light !important;
		border-bottom: none !important;
		border-right: none !important;
		border-radius: 0 !important;
		margin-left: 0px !important;
		z-index: 20;
		height: 40px;
	}


}

:global(.has-mobile-header) .tabs {
	:global .ant-tabs-nav {
		width: 100% !important;
		right: 0 !important;
		top: -49px;
	}

	:local(.buttons) {
		padding: 0 !important;
	}
}

.hiddenPaymentMethod {
	display: none;
}

.subTotal {
	:global {
		h2 {
			font-size: 40px;
			line-height: 40px;
			margin-bottom: 0;
			font-feature-settings: 'normal';
			font-variant: normal;
		}
	}
}

:global(.screen-xs) .subTotal {
	:global {
		h2 {
			font-size: 32px;
			line-height: 32px;
			margin-bottom: 0;
			font-feature-settings: 'normal';
			font-variant: normal;
		}
	}
}

.subtotalText {
	text-align: right;
	line-height: 1;
	padding: 6px 0;
	// text-transform: uppercase;
}

.keys {
	padding: 5px 24px;
	background: @background-color-light;
	box-shadow: inset 0 0px 3px #eee;
	margin-bottom: -23px;
	max-height: 39px;
	overflow: hidden;
}

// .active, .inactive {
//     width: 16px;
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
// }

.active {
	color: @primary-color;
}

.strikethrough {
	text-decoration: line-through;
}

.iconWrapper {
	text-align: center;
}

.icon {
	width: 128px;
}

.printModes {
	float: left;
	padding-top: 4px;
}

.printArea {
	display: none;
}

@media print {
	.printArea {
		display: block;
		background-color: white;
		color: #000 !important;
	}

	.printArea * {
		color: #000 !important;
	}
}

.categoryPicker {
	border-right: 1px solid rgba(0, 0, 0, 0.06);
	border-top: 1px solid rgba(0, 0, 0, 0.06);
	position: relative;
}

.categoryView {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	display: none;
	border-right: 1px solid @border-color-split;
}

.categoryView:last-child {
	display: block;
}

.searchButton {
	float: right;
	// margin-right: 8px
}

.categoryHeader {
	background-color: @component-background;
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
	padding: 8px 8px 8px 16px;
	font-weight: bold;
	cursor: pointer;

	:global {

		img {
			margin-right: 8px;
		}

		.anticon.anticon-home,
		.anticon.anticon-left {
			margin-right: 10px;
		}
	}
}

.categoryList {

	// overflow: auto;
	:global {
		img {
			width: 32px;
		}

		.ant-list-item {
			cursor: pointer;
			min-height: 41px;

			.ant-list-item-meta-title {
				font-weight: 400 !important;
				margin-bottom: 0;
			}
		}

		.ant-list-item-meta-avatar {
			padding-left: 8px;
			margin-right: 16px;
			width: 32px;

			img {
				position: absolute;
			}
		}

		.rc-virtual-list-scrollbar-show {
			display: block !important;
		}

		.ant-list-item-meta-title {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}

.categoryItem {
	position: relative;

	:global {
		.ant-list-item-meta-avatar {
			margin-left: -10px;
			padding-left: 18px;
		}

		.ant-list-item-meta-content {
			margin-left: -4px;
		}
	}
}

.productItem {
	position: relative;

	:global {
		.ant-list-item-meta-avatar {
			margin-left: -10px;
		}

		.ant-list-item-meta-content {
			margin-left: -4px;
		}
	}
}

.folder {
	width: 24px !important;
	margin-top: 0px !important;
}

.emptyCategory {
	:global {
		.ant-empty-image {
			height: 64px !important;
		}

		img {
			width: 64px !important;
			height: 64px !important;
		}
	}
}

.categoryPickerContainer {
	width: 300px;
	float: left;
	background-color: #fff;
}

.receiptData {
	width: calc(100% - 300px);
	float: left;
}

.gridRow {
	height: 80px !important;
	border-bottom: none !important;
	display: flex;
	padding: 8px 8px 0px !important;

	:global {
		.item {
			position: relative;
			display: block;
			text-align: center;

			img {
				position: relative;
				width: 48px;
				height: 48px;
				display: inline-block;
				vertical-align: initial !important;
			}

			.ant-ribbon {
				right: 0;
				top: 14px;
				font-size: 12px;
				height: 20px;
				padding: 0px 4px;
			}

			.noImage {
				display: inline-block;
				width: 48px;
				height: 48px;
				background-color: rgba(0, 0, 0, 0.06);
				align-items: center;
				font-weight: 600;
				color: rgba(0, 0, 0, 0.3);
				line-height: 48px;
			}

			.title {
				display: block;
				font-size: 10px !important;
				font-weight: 400;
				margin-top: 2px;
				text-overflow: ellipsis;
				overflow: hidden;
				height: 100%;
				min-height: 30px;
				max-height: 30px;
				max-width: 64px;
				min-width: 64px;

				line-height: 10px;
				word-break: break-word;

				@supports (-webkit-line-clamp: 3) {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: normal;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}
		}
	}
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;