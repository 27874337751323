.leftButtons {
	float: left;
}

.rightButtons {
	float: right;
	width: auto;
}

.alignLeft {
	text-align: left;
}

.centeredTitle {
	text-align: center;
}

.title {
	font-size: 16px;
	position: relative;

	:global {
		span {
			padding: 0 16px;
			background: white;
			position: relative;
		}
	}
}

.mask {
	padding: 0 16px;
	background: white;
}

.title:before {
	content: '';
	font-size: inherit;
	display: block;
	position: absolute;
	right: 0;
	left: 0;
	top: 40%;
	bottom: 40%;
	border-top: 1px solid hsv(0, 0, 85%);
	border-bottom: 1px solid hsv(0, 0, 85%);
}

.subTitle {
	position: relative;

	:global {
		span {
			padding: 0 16px;
			background: white;
			position: relative;
		}
	}
}

.subTitle:before {
	content: '';
	font-size: inherit;
	display: block;
	position: absolute;
	right: 0;
	left: 0;
	top: 50%;
	border-top: 1px solid hsv(0, 0, 85%);
}

.large {
	font-size: 24px;
}

.refundDrawer {
	:global {
		.ant-transfer-list-header {
			display: none;
		}
	}
}

.table {
	@media (max-width: 767px) {
		max-width: calc(100vw + 2px) !important;
		margin: 0 -19px !important;

	}
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;