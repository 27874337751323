@import '~antd/es/style/themes/default.less';

@keyframes slideIn {
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(0);
	}
}

.wrapper {
	display: flex;
	flex-direction: row;
	height: 100vh;
	background: url('../../../public/images/wave.svg') no-repeat;
	background-size: cover;
	background-position: center top;
}


:global {
	body.ibuddy {
		:local {
			.wrapper {
				background: url('../../../public/images/wave.ibuddy.svg') no-repeat;
				background-size: cover;
				background-position: center top;
			}

			.logoFace {
				padding-bottom: 20px;

				:global {
					img {
						filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 1));
					}
				}
			}
		}
	}
}

.logoFace {
	flex: 1;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	overflow: hidden;

	:global {
		img {
			max-width: 40vw;
			max-height: 70vh;

			:local {
				animation: .5s ease-out .3s 1 slideIn;
				transform: translateY(100%);
				animation-fill-mode: forwards;
			}
		}
	}

}

.container {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: auto;
	// background-color: @layout-body-background;
	// -webkit-app-region: drag;
}

.content {
	// background: @primary-color;
	// background: -moz-radial-gradient(center,
	// 		ellipse cover,
	// 		lighten(@primary-color, 30%) 0%,
	// 		@primary-color 100%);
	// background: -webkit-radial-gradient(center,
	// 		ellipse cover,
	// 		lighten(@primary-color, 30%) 0%,
	// 		@primary-color 100%);
	// background: radial-gradient(ellipse at center, lighten(@primary-color, 30%) 0%, @primary-color 100%);
	flex: 1;
	padding: 32px 0;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}

.top {
	text-align: center;
}

.form {
	width: 500px;
	margin: 40px auto;
	max-width: 100%;
	padding: 24px 24px 0;
	background: #fff;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	-webkit-app-region: no-drag;

	.formInner {
		padding: 0 24px;
	}
}

.sideImage {
	// background-image: url('../../assets/images/pexels-chrissy-totty-121920.jpg');
	background-size: cover;
	background-position: 50% 0%;
	height: 500px;
}

.copyright {
	text-align: center;
	color: #fff;
	opacity: 0.9;
	font-size: 12px;
	// margin-top: 24px;
	// text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
	.wrapper {
		display: block !important;
		flex-direction: column;
		height: auto;
	}


	.logoFace {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		margin-bottom: -10px;
		height: 200px;

		:global {
			img {
				height: 180px;
			}
		}

	}

	.container {
		height: auto;
	}

	.content {
		display: block !important;
		padding: 0 0 32px 0;
	}

	.form {
		margin: 10px auto 40px auto;
	}
}
@hack: ''; @import "/Users/dvsoftware/Projects/MojaMačkica/backoffice-react/src/assets/styles/index.less";@primary-color: #7E89C8;@background-color-light: hsv(0, 0, 99%);@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
			  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
			  'Noto Color Emoji' !important;@menu-icon-size-lg: 32px;